import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/home.jpg";
import saimaqr from "../../assets/images/saimaqr.png";
import Services from "./Services";
import Staff from "./Staff";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            {/* Left Side - Original */}
            <div className="col-md-5 mb-5">
              <div className="row h-100 align-items-center justify-content-center text-center">
                <div className="col-lg-10 align-self-end">
                  <img
                    src={logo}
                    className="img-fluid"
                    style={{ width: "100%" }}
                    alt="saimaskincare"
                  />
                  <h3 className="text-uppercase font-weight-bold">
                    Where Every Woman is a Star
                  </h3>
                </div>
                <div className="col-lg-8 align-self-baseline">
                  <div className="row mt-1">
                    <div className="col">
                      <Link
                        to="/giftcard"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <button
                          className="btn homebtn"
                          style={{ width: "200px" }}
                        >
                          Gift Certificate
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 mb-5"></div>
            {/* Right Side - Text above image + Download App */}
            <div
              className="col-md-5 mb-5"
              style={{
                background: "linear-gradient(white, #f0e6f5)",
                padding: "30px",
                borderRadius: "12px",
                maxWidth: "700px",
                margin: "0 auto",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                fontFamily: "Arial, sans-serif",
                color: "#333",
              }}
            >
              <div className="row h-100 align-items-center justify-content-center">
                <div className="col-lg-10">
                  <img
                    src={saimaqr}
                    className="img-fluid mb-3"
                    style={{
                      width: "50%",
                      borderRadius: "8px",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                    alt="saimaskincare"
                  />
                </div>
                <div className="col-lg-10 align-self-start mb-3">
                  <div>
                    <h4
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginBottom: "20px",
                      }}
                    >
                      Benefit of Downloading the App:
                    </h4>
                    <ul
                      style={{
                        fontSize: "13px",
                        lineHeight: "1.8",
                        paddingLeft: "20px",
                        textAlign: "left",
                      }}
                    >
                      <li>
                        <strong>25% off</strong> your first in-app purchase
                      </li>
                      <li>Collect points and rewards for every service</li>
                      <li>Access your membership information anytime</li>
                      <li>
                        Refer friends and{" "}
                        <strong>receive monetary credit</strong> toward future
                        services
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-8">
                  <button
                    className="btn homebtn"
                    style={{ width: "200px" }}
                    onClick={() => window.open("https://rpt.md/42mVZMT ")}
                  >
                    Download Our New App
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Services />
      <Staff />
      <section
        className="page-section"
        id="contact"
        style={{ backgroundImage: "linear-gradient(white, #e4d6e4)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">Let's Get In Touch!</h2>
              <hr className="divider my-4" />
              <p className=" mb-5">
                Give us a call or send us an email and we will get back to you
                as soon as possible!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
              <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
              <div>571-657-3788</div>
            </div>
            <div className="col-lg-4 mr-auto text-center mb-5 mb-lg-0">
              <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
              <a
                className="d-block"
                href="mailto:contact@inquiries@surayabeauty.com"
                style={{ color: "#690b93" }}
              >
                inquiries@surayabeauty.com
              </a>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <i className="fas fa-map-pin fa-3x mb-3 text-muted"></i>
              <a
                style={{ color: "#690b93" }}
                className="d-block"
                target="blank"
                href="https://goo.gl/maps/7szZj84DFXJ2UdR27"
              >
                Visit Us
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer
        className="bg-light py-5"
        style={{ backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)" }}
      >
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(Home);
