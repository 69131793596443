import React, { useEffect } from "react";
import touch from "../../assets/images/touch.jpg";

const TouchTherapy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          overflow:"scroll"
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(gray, #690b93)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Touch Therapy & Full body Massage Services
            </h3>
          </div>
          <div className="row pt-4">
            <div className="col-md-12">
              <table className="table table-striped table-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Service</th>
                    <th style={{width:"100px"}}>Saima's Price</th>
                    <th>Price</th>
                    <th>Duration</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1</th>
                    <td>Stone Therapy Massage</td>
                    <td>
                      --
                      <br />
                      --
                    </td>
                    <td>
                      $140.00
                      <br />
                      $160.00
                    </td>
                    <td>
                      55 min
                      <br />
                      85 min
                    </td>
                    <td>
                      Lava stones deliver deep heat for muscle relief and
                      improved circulation. The ultimate massage for full-body
                      relaxation.
                    </td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>Wholistic Head Massage</td>
                    <td>
                      --
                      <br />
                      --
                    </td>
                    <td>
                      $60.00
                      <br />
                      $110.00
                    </td>
                    <td>
                      25 min
                      <br />
                      55 min
                    </td>
                    <td>
                      Relieves allergies, TMJ, migraines, and tension with
                      deeply relaxing modalities and aromatherapy. Focuses on
                      scalp, face, neck, and shoulders.
                    </td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>Wholistic Facelift Massage</td>
                    <td>$70.00</td>
                    <td>$60.00</td>
                    <td>25 min</td>
                    <td>
                      Anti-aging massage using micro-current, cupping, pressure
                      point therapy, and lymph drainage to reduce fine lines and
                      wrinkles.
                    </td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>Manual Lymph Drainage (Head & Neck)</td>
                    <td>--</td>
                    <td>$60.00</td>
                    <td>30 min</td>
                    <td>
                      Gentle rhythmic technique to support lymph flow, detoxify,
                      and nourish skin. Ideal for post-surgery recovery and
                      edema reduction.
                    </td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>Muscle Energy Technique</td>
                    <td>
                      --
                    </td>
                    <td>
                      $70.00
                    </td>
                    <td>
                      25 min
                    </td>
                    <td>
                      Improves joint flexibility and oxygenation. Great for
                      athletes, arthritis, and fibromyalgia sufferers. Book
                      alone or add to a massage.
                    </td>
                  </tr>
                  <tr>
                    <th>6</th>
                    <td>Cupping</td>
                    <td>--</td>
                    <td>$60.00</td>
                    <td>25 min</td>
                    <td>
                      Silicone cups use negative pressure to improve circulation
                      and relieve fatigue. Can be stationary or glided across
                      the skin.
                    </td>
                  </tr>
                  <tr>
                    <th>7</th>
                    <td>Reflexology</td>
                    <td>--</td>
                    <td>
                      $60.00
                    </td>
                    <td>
                      30 min
                    </td>
                    <td>
                      Ancient technique stimulating feet, hands, and ears to
                      detoxify and balance body energy. Not recommended for
                      pregnant women.
                    </td>
                  </tr>
                  <tr>
                    <th>8</th>
                    <td>Swedish Massage</td>
                    <td>
                      --
                      <br />
                      --
                    </td>
                    <td>
                      $110.00
                      <br />
                      $130.00
                    </td>
                    <td>
                      55 min
                      <br />
                      85 min
                    </td>
                    <td>
                      Uses stroking, kneading, and tapping to reduce stress and
                      improve circulation. Produces a calming effect on the
                      nervous system.
                    </td>
                  </tr>
                  <tr>
                    <th>9</th>
                    <td>Tailored Custom Massage</td>
                    <td>
                      --
                      <br />
                      --
                    </td>
                    <td>
                      $125.00
                      <br />
                      $145.00
                    </td>
                    <td>
                      55 min
                      <br />
                      85 min
                    </td>
                    <td>
                      Our Custom Tailored Massage is the ultimate therapeutic
                      experience, designed to deliver the same benefits as a
                      deep tissue massage but customized to your specific needs.
                      This treatment focuses on relieving tension, easing muscle
                      stiffness, and addressing areas of chronic discomfort
                      through targeted, firm-pressure techniques.
                      <br />
                      Your therapist will work with you to identify problem
                      areas and apply slow, deliberate strokes to reach deeper
                      layers of muscle and connective tissue, promoting relief
                      and improved mobility. Whether you’re managing stress,
                      recovering from physical exertion, or seeking overall
                      relaxation, this personalized approach ensures a deeply
                      restorative experience tailored just for you.
                    </td>
                  </tr>
                  <tr>
                    <th>10</th>
                    <td>Muscle Energy Technique Stretching (MET)</td>
                    <td>--</td>
                    <td>
                      $100.00
                    </td>
                    <td>
                      55 min
                    </td>
                    <td>
                      Experience a personalized and precise approach to
                      improving flexibility and relieving tension with our
                      Muscle Energy Technique (MET) Stretching. This hands-on
                      technique involves calculated and guided muscle
                      contractions followed by gentle, targeted stretches,
                      designed to optimize your muscle function and joint
                      mobility.
                      <br />
                      Unlike traditional stretching, MET focuses on your active
                      participation. Under the guidance of our skilled
                      therapists, you’ll engage specific muscle groups in
                      controlled contractions, creating the perfect conditions
                      for a deeper, more effective stretch. This collaborative
                      method helps reset muscle length, reduce stiffness, and
                      enhance overall flexibility—all tailored to your unique
                      needs.
                      <br />
                      Ideal for addressing muscle tightness, postural
                      imbalances, or limited range of motion, this session
                      leaves you feeling balanced, restored, and ready to move
                      with ease.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default TouchTherapy;
