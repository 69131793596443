import React from "react";
import ver1 from "../../assets/images/missvir/ver1.jpg";
import ver2 from "../../assets/images/missvir/ver2.jpg";
import ver3 from "../../assets/images/missvir/ver3.jpg";
import ver4 from "../../assets/images/missvir/ver4.jpg";
import ver5 from "../../assets/images/missvir/ver5.jpg";
import ver6 from "../../assets/images/missvir/ver6.jpg";
import ver7 from "../../assets/images/missvir/ver7.jpg";
import ver8 from "../../assets/images/missvir/ver8.jpg";
import ver9 from "../../assets/images/missvir/ver9.jpg";
import ver10 from "../../assets/images/missvir/ver10.jpg";
import ver11 from "../../assets/images/missvir/ver11.jpg";
import ver12 from "../../assets/images/missvir/ver12.jpg";
import ver13 from "../../assets/images/missvir/ver13.jpg";
import ver14 from "../../assets/images/missvir/ver14.jpg";
import ver15 from "../../assets/images/missvir/ver15.jpg";
import ver16 from "../../assets/images/missvir/ver16.jpg";
import ver17 from "../../assets/images/missvir/ver17.jpg";

const MissVer = () => {
  const images = [
    ver1, ver2, ver3, ver4, ver5, ver6, ver7, ver8, ver9,
    ver10, ver11, ver12, ver13, ver14, ver15, ver16, ver17
  ];

  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container" style={{ textAlign: "center" }}>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-8" style={{ fontWeight: "bold" }}>
              <h3>Miss America & Miss Arlington Organization</h3>
              <h5>
                Suraya Beauty Lounge is proud to be the official sponsor for
                Miss Virginia Scholarship Organization and Miss Arlington
                Scholarship Organization.
                <br />
                Not only do we make sure our queens’ skin, lashes, and all their
                beauty needs are met to prepare them for their best appearances
                all year round, but we also collaborate with each of them to
                help achieve their community outreach goals. In return, our
                queens have been readily available to support our fundraising
                efforts and community projects.
              </h5>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-5">
            {images.map((img, index) => (
              <div className="col-md-4 my-2" key={index}>
                <img
                  src={img}
                  className="rounded"
                  alt={`miss-ver-${index + 1}`}
                  style={{ height: "300px", width: "300px", objectFit: "cover" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MissVer;
