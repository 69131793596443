import React, { useEffect } from "react";
import postcard from "../../assets/images/postcard.png";

const InfectionPrevention = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container" style={{ textAlign: "center" }}>
          <div>
            <h1>Infection Prevention and Control</h1>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={postcard}
                style={{ borderRadius: "25px", height: "350px" }}
                alt="saima"
              ></img>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row my-4">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <p style={{ fontSize: "18px" }}>
                While we have always met high sanitation standards, we’re taking
                on additional disinfection procedures. Please know that the
                wellness and safety of our customers is our number one concern,
                so we are also implementing the following procedures to protect
                the health and well-being of guests:
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <ul style={{ textAlign: "start" }}>
                <li>
                  Stringent disinfection procedures are followed between every
                  client that includes a frequent disinfection of surfaces, with
                  emphasis on door handles, counter tops and computer stations,
                  equipment, and other highly trafficked areas.
                </li>
                <li>Disinfection of equipment before and after each client.</li>
                <li>Frequent and thorough handwashing throughout the day. </li>
                <li>
                  An added extra time between clients booking to thoroughly
                  disinfect the work space.{" "}
                </li>
                <li>Distribution of hand sanitizer throughout the lounge.</li>
              </ul>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row my-4">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <p style={{ fontSize: "18px" }}>
                Please rest assured that this happens under normal
                circumstances, but we are taking additional safety measures. In
                March 2020, during the Covid-19 \quarantine, I successfully
                undertook training for Infection Prevention & Control in
                professional beauty salons and spas. The products used in the
                certification are Powerful, but Safer Choice of disinfectant for
                You.
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row my-4">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <p style={{ fontSize: "18px" }}>
                Created for spa professionals, Rejuvenate™ disinfectants are
                environmentally friendly and contain no harsh chemicals, while
                their fast-acting performance ensures killing the germs quickly
                and effectively making them safer for the users and the planet.
                Powered by Accelerated Hydrogen Peroxide® technology, Rejuvenate
                is:
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <ul style={{ textAlign: "start" }}>
                <li>EPA and FDA registered hospital grade disinfectants</li>
                <li>Non-irritating to eyes and skin, and fragrance-free</li>
                <li>
                  The active breaks down into oxygen and water, making
                  Rejuvenate the environmentally friendly choice{" "}
                </li>
                <li>
                  Fast-acting technology helps you kill germs in as little as 1
                  minute.
                </li>
              </ul>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row my-4">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <button
                type="button"
                className="btn btn-success"
                onClick={() =>
                  window.open(
                    "https://drive.google.com/file/d/1uIlAYDKb_KV-ipd1qyIpsbU76VW70rzz/view?usp=sharing"
                  )
                }
              >
                My Certificate
              </button>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row my-4">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <p style={{ fontSize: "18px" }}>
                Efficacy Against the 2019 Novel Coronavirus (2019-nCoV)
                <br />
                The following information is an excerpt from a press release
                distributed by Rejuvenate on January 27, 2020:
                <br />
                The Rejuvenate™ line of disinfectants (concentrate and pre
                moistened wipes) have demonstrated effectiveness against viruses
                similar to 2019-nCoV on hard, nonporous surfaces. Therefore, the
                Rejuvenate™ line of disinfectants can be used against 2019-nCoV
                when used in accordance with the directions for use against
                Poliovirus type 1 on hard, non-porous surfaces. Refer to the CDC
                at{" "}
                <a href="https://www.cdc.gov/outbreaks" target="blank">
                  https://www.cdc.gov/outbreaks
                </a>{" "}
                for additional information.
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <footer
        className="bg-light py-5"
        style={{ backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)" }}
      >
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default InfectionPrevention;
