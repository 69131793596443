import React from "react";
import img2 from "../../assets/images/beforeandafter/img2.jpg";
import img3 from "../../assets/images/beforeandafter/img3.jpg";
import img4 from "../../assets/images/beforeandafter/img4.jpg";
import img5 from "../../assets/images/beforeandafter/img5.jpg";
import img6 from "../../assets/images/beforeandafter/img6.jpg";
import img8 from "../../assets/images/beforeandafter/img8.jpg";
import img9 from "../../assets/images/beforeandafter/img9.jpg";
import eye1 from "../../assets/images/beforeandafter/eye1.jpeg";
import eye2 from "../../assets/images/beforeandafter/eye2.jpeg";
import eye3 from "../../assets/images/beforeandafter/eye3.jpeg";
import eye4 from "../../assets/images/beforeandafter/eye4.jpeg";
import eye5 from "../../assets/images/beforeandafter/eye5.jpeg";
import eye6 from "../../assets/images/beforeandafter/eye6.jpeg";
import eye7 from "../../assets/images/beforeandafter/eye7.jpeg";
import eye8 from "../../assets/images/beforeandafter/eye8.jpeg";
import eye9 from "../../assets/images/beforeandafter/eye9.jpeg";
import eye10 from "../../assets/images/beforeandafter/eye10.jpeg";
import eye11 from "../../assets/images/beforeandafter/eye11.jpeg";
import eye12 from "../../assets/images/beforeandafter/eye12.jpeg";
import eye13 from "../../assets/images/beforeandafter/eye13.jpeg";
import eye14 from "../../assets/images/beforeandafter/eye14.jpeg";
import eye15 from "../../assets/images/beforeandafter/eye15.jpg";

const PermanentBeforeAndAfter = () => {
  const images = [
    eye1,
    eye2,
    eye3,
    eye4,
    eye5,
    eye6,
    eye7,
    eye8,
    eye9,
    eye10,
    eye11,
    eye12,
    eye13,
    eye14,
    eye15,
  ];
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container" style={{ textAlign: "center" }}>
          <div className="row mt-3">
            <div className="col-md-3"></div>
            <div
              className="col-md-6"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                Permanent Make up Before & After
              </h3>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 my-2">
              <img
                src={img4}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img3}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img2}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img5}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img6}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img8}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img9}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            {images.map((img, index) => (
              <div className="col-md-4 my-2" key={index}>
                <img
                  src={img}
                  className="rounded"
                  alt={`eye${index + 1}`}
                  style={{ height: "300px", width: "300px" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PermanentBeforeAndAfter;
