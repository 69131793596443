import React, { useEffect } from "react";
import grooming from "../../assets/images/grooming.jpg";

const GroomingLounge = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          overflow:"scroll"
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(gray, #690b93)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              The Grooming Lounge
            </h3>
          </div>
          <div
            className="row text-center"
            style={{ justifyContent: "center" }}
          >
            <h6
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bold",
                background: "-webkit-linear-gradient(#690b93, #690b93)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              We offer waxing for the full body,
              <br /> Threading for the face and neck and Sugaring for the face,
              underarm and Brazilian bikini
            </h6>
          </div>
          <div className="row pt-4">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <table className="table table-striped table-light">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Service</th>
                    <th style={{width:"100px"}}>Saima's Price</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Brow Waxing</td>
                    <td>$20.00</td>
                    <td>$15.00</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Lip Waxing</td>
                    <td>$15.00</td>
                    <td>$15.00</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Chin Wax</td>
                    <td>$20.00</td>
                    <td>$20.00</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Full Face Waxing</td>
                    <td>$55.00</td>
                    <td>$55.00</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>Brazilian</td>
                    <td>$80.00</td>
                    <td>$65.00</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>Hi Bikini</td>
                    <td>$45.00</td>
                    <td>$45.00</td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td>Full Arm Wax</td>
                    <td>$40.00</td>
                    <td>$40.00</td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td>Half Arm Wax</td>
                    <td>$30.00</td>
                    <td>$30.00</td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td>Full Leg Wax</td>
                    <td>$90.00</td>
                    <td>$80.00</td>
                  </tr>
                  <tr>
                    <th scope="row">10</th>
                    <td>Half Leg Wax</td>
                    <td>$55.00</td>
                    <td>$50.00</td>
                  </tr>
                  <tr>
                    <th scope="row">11</th>
                    <td>Brow Threading</td>
                    <td>$20.00</td>
                    <td>$15.00</td>
                  </tr>
                  <tr>
                    <th scope="row">12</th>
                    <td>Lip Threading</td>
                    <td>$15.00</td>
                    <td>$15.00</td>
                  </tr>
                  <tr>
                    <th scope="row">13</th>
                    <td>Chin Threading</td>
                    <td>$20.00</td>
                    <td>$20.00</td>
                  </tr>
                  <tr>
                    <th scope="row">14</th>
                    <td>Neck Threading</td>
                    <td>$15.00</td>
                    <td>$15.00</td>
                  </tr>
                  <tr>
                    <th scope="row">15</th>
                    <td>Full Face Threading</td>
                    <td>$45.00</td>
                    <td>$45.00</td>
                  </tr>
                  <tr>
                    <th scope="row">16</th>
                    <td>Forehead Threading</td>
                    <td>$15.00</td>
                    <td>$15.00</td>
                  </tr>
                  <tr>
                    <th scope="row">17</th>
                    <td>Side Burn Threading</td>
                    <td>$15.00</td>
                    <td>$15.00</td>
                  </tr>
                  <tr>
                    <th scope="row">18</th>
                    <td>Chest/Back Wax</td>
                    <td>$60.00</td>
                    <td>$60.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default GroomingLounge;
