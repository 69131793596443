import React, { useEffect } from "react";
import alleviation from "../../assets/images/alleviation.jpg";
import ContactMe from "./ContactMe";

const AlleviationCare = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${alleviation})`,
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          backgroundColor: "rgba(0,0,0,0.6)",
          backgroundBlendMode: "soft-light",
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              The Alleviation Care Initiative
            </h1>
          </div>
          <div className="row text-center" style={{ justifyContent: "center" }}>
            <h4
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Specialized for you at Home
            </h4>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What is it?
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
              }}
            >
              If you have a loved one that you care to alleviate their pain,
              fatigue and suffering, this perfect package is for you. This
              initiative will help you bring the spa experience to your loved
              one's comfort of their home.
              <br />
              <br />
              During my esthetic career, I was touched by a group of clients who
              suffered from medical hardship. Some of them were still able to
              come to the spa while the ones who were terminally ill were unable
              to come see me. For those who were unable to visit the spa, I
              arranged with their families to provide the relaxing treatments at
              the comfort of my client’s home.
              <br />
              <br />
              The response to the home-based custom therapeutic treatments was
              heartwarming. My fragile client’s eyes always sparkled with
              gratitude and their faces unmistakably radiated with happiness.
              And because of the many beautiful souls that touched my heart, I
              launched The Alleviation Care Initiative. I dedicate this
              initiative to the three beautiful women who impacted me positively
              near the end of their lives and deepen my understanding for the
              spiritual value of the type of services I provide. To my beloved
              mother in law Souad Khatib, my dear clients Janice Balzarini and
              Linda Liner, I will always miss you!
            </p>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              How it Works
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
              }}
            >
              If you would like to purchase an Alleviation Care package, please
              go to the{" "}
              <span
                style={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://squareup.com/gift/ML4FTACCSXF2H/order")
                }
              >
                Gift Certificate
              </span>
              . After the purchase, <br />
              please{" "}
              <span
                style={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                data-toggle="modal"
                data-target="#contactme"
              >
                Contact Me{" "}
              </span>
              so we can arrange for the logistics of the home spa experience.
              Please remember we require two weeks advance notice for any
              Alleviation Care treatment. This time is necessary to coordinate
              logistics and ensure appropriate, individualized treatment to each
              person. Kindly keep this in mind and plan accordingly.
              <br />
              <br />
              Service duration: 2 hours including travel and set up time. Actual
              hands on treatment is 90 minutes.
              <br />
              <br />
              Cost: $250
            </p>
          </div>
        </div>
      </div>
      <ContactMe />
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
  <div className="small text-center text-muted">
    Copyright © {new Date().getFullYear()} - Surayabeautylounge
  </div>
</div>
      </footer>
    </>
  );
};

export default AlleviationCare;
