import React, { useEffect } from "react";
import tataanas from "../../assets/images/tataanas.png";

const OurStory = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <img
                className="img-fluid rounded-circle"
                src={tataanas}
                style={{ padding: "0px 90px" }}
                alt="mama"
              ></img>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3"></div>
            <div
              className="col-md-6"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                Where every woman is a star
              </h2>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row px-3" style={{ textAlign: "center" }}>
            <p style={{ fontWeight: "bolder", fontSize: "18px" }}>
              <br />
              <br />
              The name Suraya is derived from the Arabic word Thuraya, which
              means “hard to reach star”. I decided to name my beauty lounge
              Suraya as tribute to my mother who not only has a beautiful name
              that inspired me, but also has a personality that would motivate
              any woman to be a shining star. The balance between her elegance
              and tenacious personality was the blueprint of who I am today. At
              Suraya Beauty Lounge, everything was chosen with care, the name,
              the logo design, and the selection of all the tools, equipment,
              and products that will be used for treatments. Even our theme
              color, purple, was inspired by the magical view of the violet
              light scattering across the sky during sunrise.
              <br />
              <br />
              Suraya Beauty Lounge is proudly a Muslim-owned, woman-owned, and
              Palestinian-Jordanian-owned business. We are dedicated to
              fostering an inclusive and welcoming environment for all women,
              embracing diverse backgrounds, ethnicities, religions, and
              perspectives. One of our core missions is to give back to the
              community by raising awareness and hosting fundraisers throughout
              the year. We are proud to support a variety of causes that
              positively impact women and children both in the United States and
              across the globe.
            </p>
          </div>
        </div>
      </div>
      <footer
        className="bg-light py-5"
        style={{ backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)" }}
      >
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default OurStory;
