import React, { useEffect } from "react";
import alleviation from "../../assets/images/alleviation.jpg";
import ContactMe from "./ContactMe";

const PermanentMakeup = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div
        style={{
         backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          overflow:"scroll"
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(gray, #690b93)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Permanent Makeup
            </h3>
          </div>

          <div className="row pt-4">
            <div className="col-md-12">
              <table className="table table-striped table-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Service</th>
                    <th style={{width:"100px"}}>Saima's Price</th>
                    <th>Duration</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1</th>
                    <td>Microshading</td>
                    <td>$550.00</td>
                    <td>2.5 hours</td>
                    <td>
                      A semi-permanent makeup technique using a manual hand tool
                      to create natural brow strokes. Best for dry/normal skin
                      or thinning brows. Includes consultation, mapping,
                      numbing, aftercare kit. Touch-up session not included.
                    </td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>Microblading</td>
                    <td>$550.00</td>
                    <td>2.5 hours</td>
                    <td>
                      Uses pin-dot pigmentation to create a powdered brow look.
                      Great for oily, sensitive, or thin skin. Includes
                      consultation, mapping, numbing, and aftercare. Touch-up
                      not included.
                    </td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>Brow Tattoo Removal</td>
                    <td>$150.00/session</td>
                    <td>1 hour</td>
                    <td>
                      Correct unwanted PMU color or shape using a safe saline
                      solution. Gentle and effective. Multiple sessions may be
                      required.
                    </td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>Permanent Makeup Touch-up</td>
                    <td>$150.00</td>
                    <td>1.5 hours</td>
                    <td>
                      Required 6–8 weeks after initial PMU. Evaluates healing
                      and color, and refines for final look. For returning
                      clients only.
                    </td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>PMU Color Boost / Correction</td>
                    <td>$300.00</td>
                    <td>2 hours</td>
                    <td>
                      Ideal for fading brows after a year or for correcting
                      discoloration. Restores vibrancy and tone. Touch-up not
                      included.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ContactMe />

      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default PermanentMakeup;
