import React, { useEffect } from "react";
import alleviation from "../../assets/images/alleviation.jpg";
import ContactMe from "./ContactMe";

const AllAbouttheEyes = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          overflow:"scroll"
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(gray, #690b93)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              All About the Eyes
            </h3>
          </div>
          <div className="row pt-4">
            <div className="col-md-12">
              <table className="table table-striped table-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Service</th>
                    <th style={{width:"100px"}}>Saima's Price</th>
                    <th>Price</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1</th>
                    <td>Brightening Eye Treatment</td>
                    <td>$60.00</td>
                    <td>$50.00</td>
                    <td>
                      Reduces puffiness, dark circles, and fine lines with
                      exfoliation, cooling mask, and massage. Great as a
                      standalone or facial add-on.
                    </td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>Classic Lash Full Set</td>
                    <td>-</td>
                    <td>$165.00</td>
                    <td>
                      Natural lash extensions applied one-by-one with
                      hypoallergenic glue. Includes aftercare kit.
                    </td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>Hybrid Lashes Full Set</td>
                    <td>-</td>
                    <td>$185.00</td>
                    <td>
                      A mix of Classic and Volume lashes for a fuller, dramatic
                      look. Ideal for special events.
                    </td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>Volume Lashes Full Set</td>
                    <td>-</td>
                    <td>$190.00</td>
                    <td>
                      Ultra-full and bold lashes for a runway-ready style.
                      Includes an aftercare kit.
                    </td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>Mega Volume Lashes Full Set</td>
                    <td>-</td>
                    <td>$215.00</td>
                    <td>
                      Maximum volume and drama. Perfect for bold, head-turning
                      looks.
                    </td>
                  </tr>
                  <tr>
                    <th>6</th>
                    <td>Wet/Angel Lash Set</td>
                    <td>-</td>
                    <td>$185.00</td>
                    <td>
                      Feathery and textured lashes with a soft, wispy finish.
                    </td>
                  </tr>
                  <tr>
                    <th>7</th>
                    <td>Premade Any Style Lashes</td>
                    <td>-</td>
                    <td>$165.00</td>
                    <td>
                      Faster application with beautiful premade fans.
                      Time-saving and stylish.
                    </td>
                  </tr>
                  <tr>
                    <th>8</th>
                    <td>Classic Lash Fill</td>
                    <td>-</td>
                    <td>$90.00</td>
                    <td>Maintenance for classic lash sets every 2–3 weeks.</td>
                  </tr>
                  <tr>
                    <th>9</th>
                    <td>Hybrid/Angel/Wet Lash Fill</td>
                    <td>-</td>
                    <td>$110.00</td>
                    <td>
                      Maintenance for hybrid, angel, or wet sets. Keeps them
                      full and fresh.
                    </td>
                  </tr>
                  <tr>
                    <th>10</th>
                    <td>Volume Lash Fill</td>
                    <td>-</td>
                    <td>$120.00</td>
                    <td>Fills and refreshes volume lash sets.</td>
                  </tr>
                  <tr>
                    <th>11</th>
                    <td>Mega Volume Lash Fill</td>
                    <td>-</td>
                    <td>$135.00</td>
                    <td>For mega volume sets needing a bold touch-up.</td>
                  </tr>
                  <tr>
                    <th>12</th>
                    <td>Premade Lash Fill</td>
                    <td>-</td>
                    <td>$100.00</td>
                    <td>Quick fill-in for premade volume sets.</td>
                  </tr>
                  <tr>
                    <th>13</th>
                    <td>Lash Removal & Rinse</td>
                    <td>-</td>
                    <td>$60.00</td>
                    <td>
                      Safe removal and deep clean for old or damaged lash sets.
                    </td>
                  </tr>
                  <tr>
                    <th>14</th>
                    <td>Brow Tint</td>
                    <td>$20.00</td>
                    <td>$15.00</td>
                    <td>
                      Enhances color and shape of brows for a fuller appearance.
                    </td>
                  </tr>
                  <tr>
                    <th>15</th>
                    <td>Lash Tint</td>
                    <td>$30.00</td>
                    <td>$25.00</td>
                    <td>
                      Gives lashes a darker, defined look without mascara.
                    </td>
                  </tr>
                  <tr>
                    <th>16</th>
                    <td>Lash Lifting</td>
                    <td>-</td>
                    <td>$65.00</td>
                    <td>
                      Long-lasting curl and lift to your natural lashes. No
                      extensions needed.
                    </td>
                  </tr>
                  <tr>
                    <th>17</th>
                    <td>Brow Lamination</td>
                    <td>-</td>
                    <td>$65.00</td>
                    <td>
                      Straightens and sets brow hairs for a uniform, fuller
                      look.
                    </td>
                  </tr>
                  <tr>
                    <th>18</th>
                    <td>Brow Lamination & Lash Lifting</td>
                    <td>-</td>
                    <td>$90.00</td>
                    <td>
                      Combo treatment for defined brows and curled lashes — low
                      maintenance, high glam.
                    </td>
                  </tr>
                  <tr>
                    <th>19</th>
                    <td>Lash Extension Color Pop Add-on</td>
                    <td>-</td>
                    <td>$20.00</td>
                    <td>
                      Brighten your look with vibrant lash extension accents.
                    </td>
                  </tr>
                  <tr>
                    <th>20</th>
                    <td>Event Lash Boost</td>
                    <td>-</td>
                    <td>$65.00</td>
                    <td>
                      Perfect last-minute boost before special occasions — adds
                      volume and polish.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ContactMe />
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default AllAbouttheEyes;
