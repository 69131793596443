import React from "react";
import usa1 from "../../assets/images/missusa/usa1.jpg";
import usa2 from "../../assets/images/missusa/usa2.jpg";
import usa3 from "../../assets/images/missusa/usa3.jpg";
import usa4 from "../../assets/images/missusa/usa4.jpg";
import usa5 from "../../assets/images/missusa/usa5.jpg";
import usa6 from "../../assets/images/missusa/usa6.jpg";

const MissUsa = () => {
  const images = [
    usa1, usa2, usa3, usa4, usa5, usa6
  ];

  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container" style={{ textAlign: "center" }}>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-8" style={{ fontWeight: "bold" }}>
              <h3>Miss USAVA 2024</h3>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-5">
            {images.map((img, index) => (
              <div className="col-md-4 my-2" key={index}>
                <img
                  src={img}
                  className="rounded"
                  alt={`miss-ver-${index + 1}`}
                  style={{ height: "300px", width: "300px", objectFit: "fill" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MissUsa;
