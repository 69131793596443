import React from "react";
import all1 from "../../assets/images/beforeandafter/all1.jpg";
import all2 from "../../assets/images/beforeandafter/all2.jpg";
import all3 from "../../assets/images/beforeandafter/all3.jpg";
import all4 from "../../assets/images/beforeandafter/all4.jpg";
import all5 from "../../assets/images/beforeandafter/all5.jpg";
import all6 from "../../assets/images/beforeandafter/all6.jpg";
import all7 from "../../assets/images/beforeandafter/all7.jpg";
import all8 from "../../assets/images/beforeandafter/all8.jpg";

const AllAboutTheEyesBeforeAfter = () => {
  const images = [all7, all1, all5, all6, all8, all4, all3, all2];

  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container" style={{ textAlign: "center" }}>
          <div className="row mt-3">
            <div className="col-md-3"></div>
            <div className="col-md-6" style={{ fontWeight: "bold" }}>
              <h3>All About the Eyes Before & After</h3>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mt-5">
            {images.map((img, index) => (
              <div className="col-md-4 my-2" key={index}>
                <img
                  src={img}
                  className="rounded"
                  alt={`before-after-${index + 1}`}
                  style={{
                    height: "300px",
                    width: "300px",
                    objectFit: "cover",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllAboutTheEyesBeforeAfter;
