import React, { useEffect } from "react";
import saima from "../../assets/images/saima.png";
import news1 from "../../assets/images/news1.jpg";
import news2 from "../../assets/images/news2.jpg";

const SaimaProfile = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <img
                className="img rounded"
                src={saima}
                style={{ padding: "0px ",boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",height:"350px"}}
                alt="saima"
              ></img>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3"></div>
            <div
              className="col-md-6"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
                Saima Shaheen
              </h1>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row px-3" style={{ textAlign: "center" }}>
            <p style={{ fontWeight: "bolder", fontSize: "18px" }}>
              Welcome to Suraya Beauty Lounge
              <br />
              <br />
              I’m Saima Shaheen, the proud founder of Suraya Beauty Lounge, a
              space I created to help every woman feel like the radiant star she
              truly is. My passion for skincare runs deep, and for nearly two
              decades, I’ve been dedicated to helping women embrace their
              natural beauty and achieve their skincare goals.
              <br />
              <br />
              <br />
              With 19 years of experience as a Master Esthetician, including 12
              years as a spa director, I’ve worked with women of all ages and
              skin types, tailoring treatments to meet their unique needs. Over
              the last three years, I’ve expanded my expertise as a licensed
              Permanent Makeup Artist, specializing in enhancing natural beauty
              through microblading and microshading techniques to create
              beautifully contoured brows.
              <br />
              <br />
              <br />
              My certifications span a wide range of advanced skincare and
              wellness techniques, including facials, Microdermabrasion,
              Dermaplaning, Chemical Peels, Brow Contouring, Hair Removal,
              Manual Lymph Drainage, Cupping, Reflexology, and Skin Fitness.
              During the COVID-19 pandemic, I took the opportunity to further my
              education, earning certification as an infection preventionist to
              ensure my clients receive the safest and most effective treatments
              possible.
              <br />
              <br />
              <br />
              My journey began 20 years ago when I moved from Amman, Jordan, to
              Reston, Virginia. Before making the move, I earned a bachelor’s
              degree in Journalism and Public Relations and worked for a year as
              a journalist at a daily newspaper. While I loved the fast-paced
              world of journalism, becoming a mother shifted my perspective and
              priorities.
              <br />
              <br />
              <br />
              During my college summers, my mother, Suraya, encouraged me to
              take courses in essential life skills. One of those courses was in
              esthetics, sparking a passion for skincare that never faded. When
              the opportunity to change careers arose, I followed my heart,
              returning to school for comprehensive training at the French
              Academy for Beauty Arts & Techniques in Amman, Jordan. That
              decision marked the beginning of a deeply fulfilling career.
              <br />
              <br />
              <br />
              Suraya Beauty Lounge was born out of my desire to transform how
              women view skincare—not as a luxury, but as a vital part of
              self-care. Here, we see skincare as more than a routine; it’s a
              daily ritual that deserves attention and a monthly celebration
              with professional care.
              <br />
              <br />
              <br />
              When you visit Suraya Beauty Lounge, you’ll find a warm, welcoming
              space where treatments are thoughtfully customized to your
              specific needs. My goal is to help you love your skin and feel
              confident in your natural beauty. Whether you’re maintaining a
              healthy glow or addressing specific concerns, I’m here to guide
              you every step of the way.
              <br />
              <br />
              <br />I look forward to welcoming you to Suraya Beauty Lounge and
              being a part of your skincare journey!
            </p>
          </div>
          <div className="row mt-5" style={{ textAlign: "center" }}>
            <div className="col-md-2 my-2"></div>
            <div className="col-md-4 my-2">
              <img
                src={news1}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={news2}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-2 my-2"></div>
          </div>
        </div>
      </div>
      <footer
        className="bg-light py-5"
        style={{ backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)" }}
      >
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default SaimaProfile;
