import React, { useEffect } from "react";
import skincare from "../../assets/images/skincare.jpg";

const SkinCare = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          overflow:"scroll"
        }}
      >
        <div className="container">
          <div className="row text-center pt-4" style={{ justifyContent: "center" }}>
            <h3
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(gray, #690b93)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Skin Care Services
            </h3>
          </div>

          <div className="row pt-4">
            <div className="col-md-12">
              <table className="table table-striped table-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Service</th>
                    <th style={{width:"100px"}}>Saima's Price</th>
                    <th>Price</th>
                    <th>Duration</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1</th>
                    <td>Tailored Signature Facial</td>
                    <td>$140.00</td>
                    <td>$110.00</td>
                    <td>50 min</td>
                    <td>This deep cleansing facial provides a perfect level of customization to target your specific skin needs. Includes analysis, extraction, masque, and tailored massage for face, neck, and shoulders.</td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>Corrective Skin Fusion</td>
                    <td>$160.00</td>
                    <td>$135.00</td>
                    <td>50 min</td>
                    <td>Vitamin C peel, targeted infusion, and LED light therapy brighten skin, hydrate, stimulate collagen, and reduce inflammation. No extractions.</td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>Acne Assassin Series</td>
                    <td>$160.00</td>
                    <td>$135.00</td>
                    <td>50 min</td>
                    <td>Targets acne, congestion, and scarring in phases. Customized treatments for blackhead removal, scar resurfacing, and brightening. Teen favorite.</td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>Oxy Diamonds and Blades</td>
                    <td>$150.00<br/>$85.00</td>
                    <td>$150.00<br/>$85.00</td>
                    <td>50 min<br/>25 min</td>
                    <td>Combines diamond microdermabrasion or dermaplaning with oxygen infusion for exfoliation, pore tightening, and glowing skin.</td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>Pro-Design Peel</td>
                    <td>$180.00</td>
                    <td>$140.00</td>
                    <td>45 min</td>
                    <td>Penetrates skin at cellular level to reverse imperfections using fruit enzyme, Vitamin C, or chemical peels. Repeat for desired results.</td>
                  </tr>
                  <tr>
                    <th>6</th>
                    <td>Clarifying Bacne Facial</td>
                    <td>$120.00</td>
                    <td>$85.00</td>
                    <td>45 min</td>
                    <td>Targets back acne, blackheads, and scarring. Purifies skin for a smooth, confident back and shoulders.</td>
                  </tr>
                  <tr>
                    <th>7</th>
                    <td>Active Warrior Glow Facial</td>
                    <td>$160.00</td>
                    <td>$135.00</td>
                    <td>50 min</td>
                    <td>Designed for teens with active lifestyles. Deep cleans, balances oil, soothes irritation, and considers any medical skincare protocols.</td>
                  </tr>
                  <tr>
                    <th>8</th>
                    <td>Acne Assassin Glow Facial</td>
                    <td>$160.00</td>
                    <td>$135.00</td>
                    <td>50 min</td>
                    <td>Deeply purifying facial series targeting all acne stages. Clears blackheads, resurfaces scars, and brightens skin for a flawless glow.</td>
                  </tr>
                  <tr>
                    <th>9</th>
                    <td>Aqua Lift Nano Rejuvenation</td>
                    <td>$250.00</td>
                    <td>$235.00</td>
                    <td>50 min</td>
                    <td>Combines hydration, exfoliation, and nano-microneedling to improve texture, boost collagen, and brighten complexion with minimal downtime.</td>
                  </tr>
                  <tr>
                    <th>10</th>
                    <td>Diamond Velvet Glow Facial</td>
                    <td>$160.00</td>
                    <td>$155.00</td>
                    <td>50 min</td>
                    <td>Diamond microdermabrasion and nourishing serums refine texture, hydrate, and restore radiance. Minimizes pores and smooths fine lines.</td>
                  </tr>
                  <tr>
                    <th>11</th>
                    <td>Silk Radiance Dermaplaning Facial</td>
                    <td>$180.00</td>
                    <td>$155.00</td>
                    <td>50 min</td>
                    <td>Gentle dermaplaning exfoliation and brightening serums remove dead skin and peach fuzz. Enhances glow, texture, and product absorption.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default SkinCare;
