import React, { useEffect } from "react";
import alleviation from "../../assets/images/alleviation.jpg";
import ContactMe from "./ContactMe";

const PrenatalBalance = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div
        style={{
         backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          overflow:"scroll"
        }}
      >
        <div className="container">
          <div className="row text-center pt-4" style={{ justifyContent: "center" }}>
            <h3
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(gray, #690b93)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Prenatal Balance
            </h3>
          </div>

          <div className="row pt-4">
            <div className="col-md-12">
              <table className="table table-striped table-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Service</th>
                    <th>Price</th>
                    <th>Duration</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1</th>
                    <td>The Nurtured Mama Touch Therapy</td>
                    <td>$125<br/>$150</td>
                    <td>55 min<br/>85 min</td>
                    <td>
                      Designed for prenatal and postpartum mothers. Relieves pain, boosts circulation, and eases muscle tension. Provides comfort and relaxation for both mother and baby.
                    </td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>The Nurtured Radiance Facial</td>
                    <td>$150</td>
                    <td>50 min</td>
                    <td>
                      A calming facial tailored for expecting and breastfeeding moms. Gently cleanses, exfoliates, and hydrates using pregnancy-safe products to reveal a radiant complexion.
                    </td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>The Linea Nigra Sacred Glow</td>
                    <td>$120</td>
                    <td>50 min</td>
                    <td>
                      A belly facial that includes cleansing, exfoliation, mask, and massage to nourish and hydrate growing skin. Strengthens connection with baby and enhances natural glow.
                    </td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>The Luxe Mama Escape</td>
                    <td>$270</td>
                    <td>2 hours</td>
                    <td>
                      A full pampering package featuring a facial, belly glow treatment, and two grooming services. Includes partial lash extensions to prep you for postpartum glow.
                    </td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>Clean Arrival Grooming</td>
                    <td>$85</td>
                    <td>45 min</td>
                    <td>
                      Pre-birth grooming designed with care and comfort. Safe, hygienic, and customized for expectant moms looking for a polished, confident experience.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ContactMe />

      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © {new Date().getFullYear()} - Surayabeautylounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default PrenatalBalance;