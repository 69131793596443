import React from "react";
import saima from "../../assets/images/saima.png";
import fatima from "../../assets/images/fatima.jpg";
import Muna from "../../assets/images/Muna.jpg";
import Thalia from "../../assets/images/Thalia.jpg";
import { Link } from "react-router-dom";

const Staff = () => {
  return (
    <>
      <section
        className="page-section"
        id="staff"
        style={{ backgroundImage: "linear-gradient(#e4d6e4, white)" }}
      >
        <div className="container">
          <h2 className="text-center mt-0">Our Staff</h2>
          <hr className="divider my-4" />
          <div className="gtco-testimonials">
            <div className="row owl-carousel owl-carousel1 owl-theme">
              <div className="col-md-3"></div>
              <div className="col-md-6 my-2 text-center">
                <Link to="/me" style={{ textDecoration: "none" }}>
                  <img
                    src={saima}
                    alt=""
                    style={{
                      width: "200px",
                      maxWidth: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      margin: "15px auto 0",
                      boxShadow: "0 8px 20px -8px #690b93",
                      objectFit: "cover",
                    }}
                  />
                  <h2
                    className="mt-2"
                    style={{
                      color: "#690b93",
                      fontWeight: "bold",
                      background: "-webkit-linear-gradient(#690b93, #690b93)",
                      backgroundClip: "border-box",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      marginBottom: "0px",
                    }}
                  >
                    Saima Shaheen
                  </h2>

                  <h2
                    className=""
                    style={{
                      color: "#690b93",
                      background: "-webkit-linear-gradient(#690b93, #690b93)",
                      backgroundClip: "border-box",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      marginBottom: "0px",
                      fontSize: "20px",
                    }}
                  >
                    Master Esthetician
                    <br />
                    Show more...
                  </h2>
                </Link>
              </div>
              <div
                className="col-md-4 text-center my-2"
                data-toggle="modal"
                data-target="#fatimaModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={fatima}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Fatimah Darab
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Massage Therapist
                  <br />
                  Show more...
                </h2>
              </div>
              <div
                className="col-md-4 text-center my-2"
                data-toggle="modal"
                data-target="#munaModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={Muna}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Muna Asmar
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Master Esthetician
                  <br />
                  Show more...
                </h2>
              </div>
              <div
                className="col-md-4 text-center my-2"
                data-toggle="modal"
                data-target="#thaliaModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={Thalia}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Thalia Cobb
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Esthetician / Lash Specialist
                  <br />
                  Show more...
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* fatimaModal */}
      <div
        className="modal fade"
        id="fatimaModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={fatima}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Fatimah Darab
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                Fatimah Darab is a highly skilled and licensed massage therapist
                with expertise in various massage modalities, including Swedish
                Massage, Prenatal Massage, and Deep Tissue Massage. With a deep
                passion for the healing arts, Fatimah takes a holistic approach
                to wellness, combining her knowledge of body mechanics as a
                certified Yoga instructor with her massage therapy practice. Her
                comprehensive understanding of the body’s mobility allows her to
                address tension and discomfort with precision and care.
                <br />
                <br />
                Fatimah is dedicated to creating a personalized experience for
                each client. She begins every session with a thorough
                consultation, paying close attention to the client’s concerns
                and tailoring her techniques to meet their unique needs. Her
                compassionate approach and exceptional skill have made her a
                favorite since she joined our team in May, earning her a loyal
                and growing clientele.
                <br />
                <br />
                Whether you’re seeking relaxation, relief from tension, or
                support during pregnancy, Fatimah’s expertise and attentive care
                make her an outstanding choice for your wellness journey.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end fatimaModal */}
      {/* munaModal */}
      <div
        className="modal fade"
        id="munaModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={Muna}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Muna Asmar
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                Since joining our team of experts, Muna has been an absolute
                breath of fresh air. What truly sets her apart is not just her
                expertise as a Master Esthetician and skin care specialist, but
                also her extensive background in Eastern medicine, acupuncture,
                and cupping—skills she honed during her time overseas. This
                unique combination of knowledge and experience elevates her
                approach to skincare, making her one of a kind.
                <br />
                <br />
                Muna is passionate about anti-aging facials and dermaplaning,
                bringing a meticulous touch to every treatment. In addition to
                her skincare expertise, she is highly skilled in beauty
                techniques like threading, offering clients a comprehensive and
                personalized experience. Her dedication to blending traditional
                practices with modern esthetics makes her an invaluable part of
                our team.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end munaModal */}
      {/* thaliaModal */}
      <div
        className="modal fade"
        id="thaliaModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={Thalia}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Thalia Cobb
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                Thalia is the GOAT of lash artistry and the personal lash expert
                for Miss Virginia, Miss Northern Virginia, and Miss Arlington. A
                highly talented Esthetician and licensed Esthetics Instructor,
                Thalia is experienced in a wide range of beauty services. She is
                also the most sought-after lash artist in the area, known for
                her expertise, precision, and passion for creating stunning lash
                styles.
                <br />
                <br />
                Her dedication to lashes has allowed her to master every style
                and technique, ensuring each client leaves with results that
                enhance their natural beauty and accentuate their eyes. Thalia
                focuses on delivering long-lasting, customized results while
                preserving the health of her clients’ natural lashes. She
                carefully measures and selects the appropriate lash lengths and
                weights to avoid breakage or damage, keeping the natural lashes
                healthy and strong.
                <br />
                <br />
                Thalia also prioritizes client education, teaching proper
                cleaning and care techniques to maximize the longevity of lash
                extensions and maintain their flawless appearance. As a
                professional Esthetician and certified Lash Stylist, she places
                cleanliness and hygiene at the forefront of her work. Thalia
                follows strict sterility practices and hygiene protocols to
                minimize the risk of contamination and infection, ensuring the
                safety and satisfaction of every client.
                <br />
                <br />
                In addition to her lash expertise, clients love Thalia’s skill
                in waxing, lash lifting, and brow lamination, making her a true
                beauty industry expert. Whether you’re looking for enhanced
                lashes or expert brow and waxing services, Thalia provides
                unparalleled artistry and care.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end thaliaModal */}
    </>
  );
};

export default Staff;
